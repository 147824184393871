import styled from "styled-components";
import media from "constants/media";

/* Use ::before with opacity to avoid non-composited animations
 * See: https://web.dev/non-composited-animations/
 */
export const Container = styled.header`
    position: fixed;
    top: 0;
    z-index: 30;

    display: flex;
    justify-content: center;
    width: 100%;

    :after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        width: 100%;
        height: 100%;

        background-color: var(
            --color-${({ isChristmas }) => (isChristmas ? "red-500" : "secondary")}
        );
        opacity: ${({ sticky }) => (sticky ? 0 : 1)};

        transition: all var(--animation-duration-default) ease;

        content: "";
    }
`;

export const Wrapper = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    height: 55px;
    padding: 0 10px;

    ${media.mdUp} {
        height: 90px;
        margin: 0 -50%;
    }
`;

export const MenuButton = styled.button`
    padding: 0;

    background-color: transparent;
    border: 0;
    cursor: pointer;
`;

export const MenuText = styled.span`
    margin-left: 10px;

    color: var(--color-tertiary);
    font-size: 12px;
    line-height: 30px;
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    z-index: 1;

    width: 100vw;
    height: 100vh;

    background: var(--color-gray-300);
    visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
    opacity: ${({ isOpen }) => (isOpen ? 0.5 : 0)};

    transition: all var(--animation-duration-default) ease;

    pointer-events: none;
`;

import styled, { css } from "styled-components";

import media from "constants/media";

export const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;

    width: 320px;
    height: 531px;
    padding: 15px 10px 20px;

    background-image: url("/images/shapes/header-nav-bg-mobile.svg");
    background-repeat: no-repeat;
    transform: translate(-30px, 0);
    visibility: hidden;
    opacity: 0;

    transition: all var(--animation-duration-cardExpand)
        var(--animation-easing-standard);

    ${({ visible }) =>
        visible &&
        css`
            opacity: 1;
            transform: translate(0, 0);
            visibility: visible;
            content-visibility: visible;
        `}

    ${media.mdUp} {
        padding-top: 30px;
    }

    ${media.lgUp} {
        width: 100%;
        height: auto;

        background-image: none;
        transform: translate(0, ${({ visible }) => (visible ? "0" : "-30px")});

        &::before {
            position: absolute;
            top: 0;
            left: -170px;
            z-index: 0;

            display: block;
            width: 1508px;
            height: 208px;

            background-image: url("/images/shapes/header-nav-bg-desktop.svg");

            content: "";
        }
    }
`;

export const MenuButton = styled.button`
    position: relative;

    margin-left: -5px;
    padding: 0;

    color: var(--color-white);
    font-size: 14px;
    line-height: 30px;

    background-color: transparent;
    border: 0;
    cursor: pointer;
`;

export const MainNavigation = styled.nav`
    margin: 0;
    padding: 0;

    ${media.lgUp} {
        margin-top: 30px;
    }
`;

export const NavList = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
    padding: 0;

    list-style: none;

    ${media.lgUp} {
        flex-direction: row;
        justify-content: space-between;
    }

    li {
        &:first-of-type {
            margin-top: 25px;

            ${media.lgUp} {
                margin-top: 0;
            }
        }
    }
`;

export const NavLink = styled.a`
    position: relative;

    display: inline-block;
    margin: 0 0 20px;

    color: var(--color-white);
    font-size: 16px;
    font-family: var(--font-family-secondary);
    line-height: 24px;

    transition: color var(--animation-duration-default) ease;

    &:last-of-type {
        margin-right: 0;
    }

    &:hover {
        color: var(--color-tertiary);
    }

    ${media.lgUp} {
        margin: 0;
    }

    ${({ isActive }) =>
        isActive &&
        css`
            padding: 0 0 0 53px;

            :before {
                position: absolute;
                top: 5px;
                left: 0;

                display: block;
                height: 12px;
                width: 46px;

                background-image: url("/images/shapes/nav-active.svg");

                content: "";
            }

            ${media.lgUp} {
                padding: 0;

                :before {
                    top: unset;
                    bottom: -10px;

                    width: 100%;
                }
            }
        `}
`;

import PropTypes from "prop-types";
import { useState, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useClickAway } from "react-use";

import IconHamburger from "components/ui/icons/IconHamburger";

import Logo from "./logo/Logo";
import OrderButton from "./orderButton/OrderButton";
import Navigation from "./navigation/Navigation";

import {
    Container,
    Wrapper,
    MenuButton,
    MenuText,
    Overlay
} from "./Header.styled";

function Header({
    showOrderButton,
    showMobileLogo,
    mobileLogoColor,
    desktopLogoColor,
    buttonColor,
    isChristmas
}) {
    const [open, setOpen] = useState(false);
    const refNavigation = useRef();
    const { ref, inView } = useInView({
        threshold: 1,
        initialInView: true
    });

    const orderButtonColor = isChristmas && !inView ? "white" : buttonColor;
    useClickAway(refNavigation, () => setOpen(false));

    return (
        <>
            <span ref={ref} id="top-of-page" />
            <Container
                isChristmas={isChristmas}
                ref={refNavigation}
                sticky={inView}
            >
                <Wrapper>
                    <MenuButton onClick={() => setOpen(!open)}>
                        <IconHamburger size={28} />
                        <MenuText>menu</MenuText>
                    </MenuButton>
                    <Navigation setOpen={() => setOpen()} isOpen={open} />
                    <Logo
                        sticky={!inView}
                        showMobileLogo={showMobileLogo}
                        mobileLogoColor={mobileLogoColor}
                        desktopLogoColor={desktopLogoColor}
                    />
                    {showOrderButton && (
                        <OrderButton color={orderButtonColor} />
                    )}
                </Wrapper>
                <Overlay isOpen={open} />
            </Container>
        </>
    );
}

Header.propTypes = {
    showOrderButton: PropTypes.bool,
    showMobileLogo: PropTypes.bool,
    mobileLogoColor: PropTypes.oneOf(["blue", "white", "full-color"]),
    desktopLogoColor: PropTypes.oneOf(["white", "full-color"]),
    buttonColor: PropTypes.oneOf(["blue", "white"]),
    isChristmas: PropTypes.bool
};

Header.defaultProps = {
    showOrderButton: true,
    showMobileLogo: true,
    mobileLogoColor: "blue",
    desktopLogoColor: "white",
    buttonColor: "blue",
    isChristmas: false
};

export default Header;

import styled, { css } from "styled-components";

import media from "constants/media";

const roundedButtonStyling = css`
    margin: 0;
    padding: 10px 18px;

    font-weight: var(--font-weight-medium);
    font-size: 15px;
    line-height: 30px;

    border-radius: var(--border-radius-medium);

    transition: background var(--animation-duration-default) ease;
`;

const primaryButtonStyles = css`
    color: var(--color-white);

    background-color: var(--color-primary);
    border: 0;
    box-shadow: var(--box-shadow-mobile);

    ${roundedButtonStyling}

    &:hover,
    &:focus {
        color: var(--color-white);

        background: var(--color-blue-300);
    }

    ${media.mdUp} {
        box-shadow: var(--box-shadow-desktop);
    }
`;

const secondaryButtonStyles = css`
    color: var(--color-primary);

    background-color: var(--color-white);
    border: 0;
    box-shadow: var(--box-shadow-mobile);

    ${roundedButtonStyling}

    &:hover,
    &:focus {
        background: var(--color-gray-600);
    }

    ${media.mdUp} {
        box-shadow: var(--box-shadow-desktop);
    }
`;

const tertiaryButtonStyles = css`
    color: var(--color-white);

    background-color: var(--color-purple-400);
    border: 0;
    box-shadow: var(--box-shadow-mobile);

    ${roundedButtonStyling}

    &:hover,
    &:focus {
        color: var(--color-white);

        background: var(--color-purple-300);
    }

    ${media.mdUp} {
        box-shadow: var(--box-shadow-desktop);
    }
`;

const outlinedButtonStyles = css`
    color: var(--color-primary);

    background-color: transparent;
    border: 1px solid var(--color-primary);

    ${roundedButtonStyling}

    svg {
        fill: var(--color-primary);
    }

    &:hover,
    &:focus {
        color: var(--color-white);

        background: var(--color-primary);

        svg {
            fill: var(--color-white);
        }
    }
`;

const ghostButtonStyled = css`
    margin: 8px 0;
    padding: 2px 0;

    color: var(--color-secondary);
    font-weight: var(--font-weight-medium);
    font-size: 15px;
    line-height: 30px;

    background: transparent;
    border-bottom: 1px solid var(--color-secondary);
    border-radius: 0;

    transition: color var(--animation-duration-default) ease-in-out;

    &:hover,
    &:focus {
        color: var(--color-primary);

        border-bottom-color: var(--color-primary);
    }
`;

const inlineButtonStyled = css`
    margin: 0;
    padding: 10px 18px;

    color: var(--color-secondary);
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    text-decoration: underline;

    background: transparent;
    border: 0;

    transition: color var(--animation-duration-default) ease-in-out;

    &:hover,
    &:focus {
        color: var(--color-peach-500);
    }
`;

const simpleLinkButtonStyled = css`
    margin: 0;
    padding: 0;

    color: var(--color-primary);
    font-weight: var(--font-weight-normal);
    font-size: 14px;
    line-height: 30px;

    background: transparent;
    border: 0;

    transition: color var(--animation-duration-default) ease-in-out;

    &:hover,
    &:focus {
        color: var(--color-blue-300);
    }
`;

const simpleUnderlinedButtonStyled = css`
    text-decoration: underline;

    ${simpleLinkButtonStyled}
`;

const ButtonStyles = css`
    position: relative;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    outline: 0;
    cursor: pointer;

    user-select: none;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }

    ${({ variant }) =>
        (variant === "primary" && primaryButtonStyles) ||
        (variant === "secondary" && secondaryButtonStyles) ||
        (variant === "tertiary" && tertiaryButtonStyles) ||
        (variant === "outlined" && outlinedButtonStyles) ||
        (variant === "ghost" && ghostButtonStyled) ||
        (variant === "inline" && inlineButtonStyled) ||
        (variant === "link" && simpleLinkButtonStyled) ||
        (variant === "underlined" && simpleUnderlinedButtonStyled)}
`;

export const ButtonStyled = styled.button`
    ${ButtonStyles}
`;

import PropTypes from "prop-types";

function IconChevronRight({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            {...rest}
        >
            <path d="m21.6 47.4c-.8.8-.8 2.2 0 3s2.2.8 3 0l18.4-18.4-18.4-18.4c-.8-.8-2.2-.8-3 0s-.8 2.2 0 3l15.4 15.4z" />
        </svg>
    );
}

IconChevronRight.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconChevronRight.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconChevronRight;

import PropTypes from "prop-types";

function IconHamburger({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            {...rest}
        >
            <path d="M60,42 L60,44 L4,44 L4,42 L60,42 Z M45,32 L45,34 L4,34 L4,32 L45,32 Z M45,22 L45,24 L4,24 L4,22 L45,22 Z" />
        </svg>
    );
}

IconHamburger.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconHamburger.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconHamburger;

import styled from "styled-components";

import media from "constants/media";

import IconShoppingBag from "components/ui/icons/IconShoppingBag";

export const StyledIcon = styled(IconShoppingBag)`
    margin-left: 10px;
`;

export const Text = styled.span`
    color: var(
        --color-${({ color }) => (color === "blue" ? "primary" : color)}
    );

    ${media.mdUp} {
        color: var(--color-white);
    }
`;

import PropTypes from "prop-types";

function IconCross({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            {...rest}
        >
            <path d="M44.991378,16 L47.1126984,18.1213203 L33.677,31.556 L47.1126984,44.991378 L44.991378,47.1126984 L31.556,33.677 L18.1213203,47.1126984 L16,44.991378 L29.435,31.556 L16,18.1213203 L18.1213203,16 L31.556,29.435 L44.991378,16 Z" />
        </svg>
    );
}

IconCross.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconCross.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconCross;

import { forwardRef } from "react";
import PropTypes from "prop-types";

import { ButtonStyled } from "./Button.styled";

const Button = forwardRef(({ children, element, ...rest }, ref) => {
    return (
        <ButtonStyled as={element} ref={ref} {...rest}>
            {children}
        </ButtonStyled>
    );
});

Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    element: PropTypes.oneOf(["button", "a"]),
    variant: PropTypes.oneOf([
        "primary",
        "secondary",
        "tertiary",
        "ghost",
        "outlined",
        "inline",
        "link",
        "underlined"
    ])
};

Button.defaultProps = {
    element: "button",
    variant: "primary",
    className: ""
};

export default Button;

import PropTypes from "prop-types";

function IconShoppingBag({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            {...rest}
        >
            <path d="M39.8185427,10.5060439 L39.9113206,10.6246925 L50.175,25.212 L53.4824968,25.2120392 C54.9864634,25.2120392 56.1013204,26.6052883 55.7744647,28.0741651 L50.282226,52.6148934 C50.0418182,53.6868757 49.0893602,54.4497668 47.9904001,54.4497668 L16.8415465,54.4497668 C15.7425864,54.4497668 14.7901284,53.6868757 14.5495786,52.6142595 L9.05709099,28.0724131 C8.73062621,26.6052883 9.84548321,25.2120392 11.3494498,25.2120392 L14.654,25.212 L24.9208279,10.6246147 C25.3592404,10.0016677 26.193268,9.82248838 26.8433209,10.1878479 L26.9707068,10.2682101 C27.5936538,10.7066227 27.7728331,11.5406502 27.4074736,12.1907032 L27.3271113,12.318089 L18.252,25.212 L46.578,25.212 L37.5049277,12.3180112 C37.0665554,11.6950358 37.1794544,10.8494821 37.7428161,10.3609333 L37.8614648,10.2681554 C38.4844402,9.82978314 39.3299939,9.94268214 39.8185427,10.5060439 Z M52.7412412,28.1544966 L12.0907065,28.1544966 L17.3170883,51.5073095 L47.5148582,51.5073095 L52.7412412,28.1544966 Z M36.7363835,35.0730966 C37.5011243,35.0730966 38.1295867,35.6565742 38.2008773,36.4026361 L38.2076121,36.5443253 L38.2076121,44.8082168 C38.2076121,45.6207539 37.5489206,46.2794455 36.7363835,46.2794455 C35.9716426,46.2794455 35.3431802,45.6959679 35.2718896,44.9499059 L35.2651548,44.8082168 L35.2651548,36.5443253 C35.2651548,35.7317881 35.9238463,35.0730966 36.7363835,35.0730966 Z M28.0957103,35.0730966 C28.9082475,35.0730966 29.566939,35.7317881 29.566939,36.5443253 L29.566939,36.5443253 L29.566939,44.8082168 C29.566939,45.6207539 28.9082475,46.2794455 28.0957103,46.2794455 C27.2831731,46.2794455 26.6244816,45.6207539 26.6244816,44.8082168 L26.6244816,44.8082168 L26.6244816,36.5443253 C26.6244816,35.7317881 27.2831731,35.0730966 28.0957103,35.0730966 Z" />
        </svg>
    );
}

IconShoppingBag.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconShoppingBag.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconShoppingBag;

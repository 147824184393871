import PropTypes from "prop-types";

import colors from "constants/colors";

function LogoSwCircle({
    fillColorCircle,
    fillColorLetter,
    height,
    width,
    ...props
}) {
    return (
        <svg viewBox="0 0 91 91" width={width} height={height} {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M88.297 30.475C82.304 12.777 65.346 0 45.32 0 20.291 0 0 19.94 0 44.541c0 24.597 20.291 44.538 45.32 44.538 25.031 0 45.321-19.941 45.321-44.538a44.86 44.86 0 00-.228-4.467c-.638-5.057-.638-5.057-2.116-9.599z"
                    fill={colors[fillColorLetter]}
                />
                <path
                    d="M74.786 53.675c0 16.016-12.002 24.17-29.381 24.17-15.027 0-28.79-7.865-28.79-21.39 0-10.458 8.001-15.445 14.641-16.306-8.199-3.643-10.836-7.959-10.836-14.004 0-5.944 4.007-14.581 18.935-14.581 12.984 0 17.765 5.566 17.765 11.893 0 3.842-3.508 6.139-5.852 6.139 0-10.741-6.543-13.425-11.913-13.425-5.951 0-11.419 4.03-11.419 9.974 0 11.513 16.887 9.782 16.887 16.404 0 .959-.195 2.491-1.656 4.222-.688-.863-2.148-2.498-6.247-2.498-6.934 0-12.691 5.278-12.691 12.28 0 12.179 13.469 16.788 21.176 16.788 14.74 0 22.65-7.677 22.65-16.788.198-2.491.198-3.739.198-11.513 0-2.878-.977-3.354-3.616-3.354-5.951 0-9.466 2.207-9.466 6.422 0 4.124 4.974 5.567 7.024 5.567 0 0-.387 4.511-4.681 4.511-4.976 0-8.69-4.316-8.69-10.359 0-4.03 2.828-10.748 15.813-10.748h14.839c4.664 0 10.299-2.062 8.821-6.604C82.304 12.777 65.346 0 45.32 0 20.291 0 0 19.94 0 44.541c0 24.597 20.291 44.538 45.32 44.538 25.031 0 45.321-19.941 45.321-44.538a44.86 44.86 0 00-.228-4.467c-1.134.8-2.499 1.419-4.397 1.419-2.055 0-3.129-.096-5.762-.096-5.172 0-5.468 4.6-5.468 12.278z"
                    fill={colors[fillColorCircle]}
                />
            </g>
        </svg>
    );
}

LogoSwCircle.propTypes = {
    fillColorCircle: PropTypes.oneOf([...Object.keys(colors), "none"]),
    fillColorLetter: PropTypes.oneOf([...Object.keys(colors), "none"]),
    height: PropTypes.number,
    width: PropTypes.number
};

LogoSwCircle.defaultProps = {
    fillColorCircle: colors.blue500,
    fillColorLetter: colors.white,
    height: 30,
    width: 30
};

export default LogoSwCircle;

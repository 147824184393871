import PropTypes from "prop-types";
import { useRouter } from "next/router";

import { VisuallyHidden } from "theme/mixins";

import IconCross from "components/ui/icons/IconCross";

import {
    Container,
    MenuButton,
    MainNavigation,
    NavList,
    NavLink
} from "./Navigation.styled";

const navigationItems = [
    {
        url: "/",
        label: "Home"
    },
    {
        url: "/saunabon-bestellen/",
        label: "Bestellen"
    },
    {
        url: "/saldocheck/",
        label: "Saldocheck"
    },
    {
        url: "/resorts/",
        label: "Onze locaties"
    },
    {
        url: "/verkooppunten/",
        label: "Verkooppunten"
    },
    {
        url: "/acceptant-worden/",
        label: "Acceptant"
    },
    {
        url: "/zakelijk/",
        label: "Zakelijk"
    },
    {
        url: "/helpdesk/",
        label: "Helpdesk"
    },
    {
        url: "/verdubbelaar/",
        label: "Verdubbelaar"
    }
];

function Navigation({ setOpen, isOpen }) {
    const router = useRouter();
    const currentUrl = router ? router.pathname : "/";

    return (
        <Container visible={isOpen}>
            <MenuButton onClick={() => setOpen(!isOpen)}>
                <IconCross size={28} fill="var(--color-white)" />{" "}
                <VisuallyHidden>menu</VisuallyHidden> sluiten
            </MenuButton>
            <MainNavigation role="navigation">
                <NavList>
                    {navigationItems?.map(item => (
                        <li key={item.label}>
                            <NavLink
                                href={item.url}
                                isActive={item.url === currentUrl}
                                prefetch={false}
                            >
                                {item.label}
                            </NavLink>
                        </li>
                    ))}
                </NavList>
            </MainNavigation>
        </Container>
    );
}

Navigation.defaultProps = {
    isOpen: false
};

Navigation.propTypes = {
    setOpen: PropTypes.func.isRequired,
    isOpen: PropTypes.bool
};

export default Navigation;

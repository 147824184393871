import styled, { css } from "styled-components";

import LogoSwCircle from "components/ui/svg/LogoSwCircle";

export const StyledMobileLogo = styled(LogoSwCircle)`
    opacity: 0;

    transition: ease var(--animation-duration-default) all;

    ${({ isVisible }) =>
        isVisible &&
        css`
            opacity: 1;
        `};
`;

export const Anchor = styled.a`
    position: absolute;
    left: 50%;

    display: inline-flex;
    margin: 0 auto;

    transform: translate(-50%, 0);
    cursor: pointer;
`;

import PropTypes from "prop-types";
import NextHead from "next/head";
import { useRouter } from "next/router";

function parseCanonical(canonical, path = "") {
    if (canonical && typeof canonical === "string" && canonical !== "") {
        return canonical;
    }

    const url = new URL(`${process.env.NEXT_PUBLIC_BASE_URL}${path}`);
    return `${process.env.NEXT_PUBLIC_BASE_URL}${url.pathname}`;
}

function Head({ siteName, title, description, keywords, canonical }) {
    const router = useRouter();
    const canonicalUrl = parseCanonical(canonical, router.asPath);

    return (
        <NextHead>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords.toString()} />
            <link rel="canonical" href={canonicalUrl} />
            <meta name="application-name" content={siteName} />
            <meta name="apple-mobile-web-app-title" content={siteName} />
            <meta property="og:site_name" content={siteName} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
        </NextHead>
    );
}
Head.propTypes = {
    siteName: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    canonical: PropTypes.string
};

Head.defaultProps = {
    siteName: "Sauna & Wellness Cadeaukaart",
    title: "Saunabon voor heel Nederland | Sauna & Wellness Cadeaukaart",
    description:
        "Saunabon cadeau geven? Ga dan voor de Sauna & Wellness Cadeaukaart. Met keuze uit meer dan 210 sauna's op mooie locaties door heel Nederland.",
    keywords: ["Sauna Wellness"],
    canonical: undefined
};

export default Head;

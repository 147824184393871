import PropTypes from "prop-types";

function IconChevronDown({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            {...rest}
        >
            <path d="m16.6 21.6c-.8-.8-2.2-.8-3 0s-.8 2.2 0 3l18.4 18.4 18.4-18.4c.8-.8.8-2.2 0-3s-2.2-.8-3 0l-15.4 15.4z" />
        </svg>
    );
}

IconChevronDown.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconChevronDown.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconChevronDown;

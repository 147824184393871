import PropTypes from "prop-types";
import Link from "next/link";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import Button from "components/ui/button/Button";

import { StyledIcon, Text } from "./OrderButton.styled";

function OrderButton({ color }) {
    const { mobileView } = useMediaQueryContext();

    return (
        <Link href="/saunabon-bestellen/" passHref prefetch={false}>
            <Button as="a" variant={mobileView ? "link" : "primary"}>
                <Text color={color}>
                    {mobileView ? "Bestellen" : "Direct bestellen"}
                </Text>
                {!mobileView && (
                    <StyledIcon
                        size={21}
                        fill="var(--color-white)"
                        aria-hidden="true"
                    />
                )}
            </Button>
        </Link>
    );
}

OrderButton.propTypes = {
    color: PropTypes.oneOf(["blue", "white"])
};

OrderButton.defaultProps = {
    color: "blue"
};

export default OrderButton;

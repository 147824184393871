import PropTypes from "prop-types";
import Link from "next/link";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import { Anchor, StyledMobileLogo } from "./Logo.styled";

function Logo({ sticky, showMobileLogo, mobileLogoColor, desktopLogoColor }) {
    const { mobileView } = useMediaQueryContext();
    const letterColor = mobileLogoColor === "full-color" ? "white" : "none";
    const circleColor = mobileLogoColor === "white" ? "white" : "primary";
    const desktopColor = desktopLogoColor === "white" ? "white" : "color";

    return (
        <Link href="/" passHref prefetch={false}>
            <Anchor title="Naar de homepagina ">
                {mobileView && (
                    <StyledMobileLogo
                        isVisible={showMobileLogo || sticky}
                        fillColorCircle={circleColor}
                        fillColorLetter={letterColor}
                    />
                )}
                {!mobileView && (
                    <img
                        src={`/images/logos/logo-sauna-wellness-${desktopColor}.svg`}
                        alt="Logo Sauna & Wellness Cadeaukaart"
                        width={176}
                        height={32}
                        loading="eager"
                    />
                )}
            </Anchor>
        </Link>
    );
}

Logo.propTypes = {
    showMobileLogo: PropTypes.bool,
    sticky: PropTypes.bool.isRequired,
    mobileLogoColor: PropTypes.oneOf(["blue", "white", "full-color"]),
    desktopLogoColor: PropTypes.oneOf(["white", "full-color"])
};

Logo.defaultProps = {
    showMobileLogo: true,
    mobileLogoColor: "blue",
    desktopLogoColor: "white"
};

export default Logo;
